import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faGithub,
  faLinkedin,
  faInstagram,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

function Footer(props) {
  let date = new Date().getFullYear();
  return (
    <footer
      className={`${props.darkMode}:bg-black ${props.darkMode}:text-white text-xs p-3 absolute bottom-0 w-full px-4 lg:px-40 xl:px-56 flex-none flex-row-reverse lg:flex justify-between`}
    >
      <div className='text-2xl pb-2 flex items-center justify-center'>
        <span className='px-3'>
          <a
            href='https://github.com/KiranRambha'
            target='_blank'
            rel='noreferrer'
          >
            <FontAwesomeIcon icon={faGithub} />
          </a>
        </span>
        <span className='px-3'>
          <a
            href='https://uk.linkedin.com/in/kiranrambha'
            target='_blank'
            rel='noreferrer'
          >
            <FontAwesomeIcon icon={faLinkedin} />
          </a>
        </span>
        <span className='px-3'>
          <a
            href='https://www.instagram.com/kiran.film'
            target='_blank'
            rel='noreferrer'
          >
            <FontAwesomeIcon icon={faInstagram} />
          </a>
        </span>
        <span className='px-3'>
          <a
            href='https://twitter.com/kiran_rambha'
            rel='noreferrer'
            target='_blank'
          >
            <FontAwesomeIcon icon={faTwitter} />
          </a>
        </span>
        <span className='px-3 lg:pl-3 lg:pr-0'>
          <a
            href='mailto:kiran.rambha@outlook.com'
            target='_blank'
            rel='noreferrer'
          >
            <FontAwesomeIcon icon={faEnvelope} />
          </a>
        </span>
      </div>
      <div className='flex items-center justify-center'>
        <div className='text-center lg:text-left'>
          <span>
            <span>&copy; </span>
            <span className='font-header'>
              Copyright {date} <b>KIRAN RAMBHA</b>
            </span>
          </span>
          <br />
          <span className='pl-0 lg:pl-3.5 font-main'>
            Made with ❤️ in <b>London</b>
          </span>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
