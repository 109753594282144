import React from 'react';
import { VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function One({ color, icon }) {
  return (
    <VerticalTimelineElement
      iconStyle={{
        background: 'rgb(16, 204, 82)',
        color,
        boxShadow: `0 0 0 4px ${color}, inset 0 2px 0 rgba(0,0,0,.08), 0 3px 0 4px rgba(0,0,0,.05)`,
      }}
      icon={<FontAwesomeIcon icon={icon} />}
    />
  );
}
