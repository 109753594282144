import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';

export default function About() {
  return (
    <section className='About'>
      <div>
        <div className='font-header font text-xl pb-3'>
          <FontAwesomeIcon icon={faUser} />
          <u className='pl-2 font-bold font-main'>About</u>
        </div>
        <div className='pb-10'>
          A full-stack web developer with over 7 years of experience, currently
          working with different JavaScript technologies like{' '}
          <b>
            <i>NodeJS</i>
          </b>
          ,{' '}
          <b>
            <i>ReactJS</i>
          </b>{' '}
          and many other cloud services in{' '}
          <b>
            <i>GCP</i>
          </b>
          ,{' '}
          <b>
            <i>AWS</i>
          </b>{' '}
          and{' '}
          <b>
            <i>Azure</i>
          </b>{' '}
          Stack. I also have experience in developing high-quality large scale
          web application using{' '}
          <b>
            <i>ASP.NET CORE</i>
          </b>
          ,{' '}
          <b>
            <i>C#</i>
          </b>{' '}
          as well as developing Amazon Alexa skills using{' '}
          <b>
            <i>Alexa Skill Kit</i>
          </b>{' '}
          and{' '}
          <b>
            <i>AWS Lambda</i>
          </b>
          .
        </div>
        <div className='pb-10'>
          Along with being a full-stack developer, I really enjoy photography
          especially landscape and street photography. I recently started
          exploring the world of film photography and I love learning all the
          ins and outs of different film stocks and film cameras. Aside from
          photography I like to spend my free time at the gym or taking long
          walks at the local park. I also like to travel and explore food and
          culture from different countries.
        </div>
      </div>
    </section>
  );
}
